import { up } from 'styled-breakpoints'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.spacing.cozy * 9 + props.theme.dimensions.buttonHeight}px;

  > *:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.gray};
  }

  ${up('md')} {
    flex: 1;
    margin-bottom: 0;
    overflow-x: auto;
  }
`

export const StyledForm = styled.form.attrs(() => ({ id: 'repurchase-form' }))`
  display: flex;
  flex: 1;
  flex-direction: column;
`
