import * as React from 'react'
import { isFinite } from 'lodash'
import Link from 'next/link'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import { Price } from '~/components'
import Anchor from '~/truck/anchor'
import Button from '~/truck/button'

export interface RepurchaseButtonProps {
  cartTotal: number
  loading?: boolean
  style?: React.CSSProperties
  className?: string
}

const StyledButton = styled(Button)`
  justify-content: ${props => (props.loading ? 'center' : 'space-between')};

  ${up('md')} {
    width: ${props => props.theme.spacing.cozy * 37}px;
  }
`

const Container = styled.div`
  background-color: ${props => props.theme.colors.grayLight};
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  padding: ${props => props.theme.spacing.comfortable}px;
  position: fixed;
  width: 100%;
  z-index: 10;

  > a {
    margin-top: ${props => props.theme.spacing.comfortable}px;
    text-align: center;
  }

  ${up('md')} {
    align-items: center;
    background-color: unset;
    bottom: unset;
    flex-direction: row;
    justify-content: flex-end;
    position: unset;

    > a {
      margin-right: ${props => props.theme.spacing.comfortable}px;
      margin-top: 0;
    }
  }
`

export function RepurchaseButton(props: RepurchaseButtonProps) {
  const { style, className, cartTotal, loading } = props

  const disabled = !isFinite(cartTotal) || cartTotal === 0

  return (
    <Container style={style} className={className}>
      <Link href="/" passHref>
        <Anchor $textStyle="h5Semibold">Seguir comprando</Anchor>
      </Link>
      <StyledButton loading={loading} type="submit" color="secondary" disabled={disabled}>
        Agregar al carrito
        <Price
          value={cartTotal}
          textStyle="h5Semibold"
          symbolTextStyle="h5Semibold"
          color={disabled ? 'blackLight' : 'white'}
        />
      </StyledButton>
    </Container>
  )
}
