import * as React from 'react'

import { EmptyState } from '~/components'
import type { EmptyStateProps } from '~/components/empty-state'
import { images } from '~/utils/constants'

import { RepurchaseHeader, StyledContainer } from '../header'

export interface RepurchaseEmptyStateLayoutProps {
  title: string
  children: React.ReactNode
}

export interface RepurchaseEmptyStateProps {
  title: string
}

const commonProps: Pick<EmptyStateProps, 'actionProps'> = {
  actionProps: {
    route: '/',
    label: 'Explorar tienda',
  },
}

function RepurchaseEmptyStateLayout(props: RepurchaseEmptyStateLayoutProps) {
  const { title, children } = props

  return (
    <>
      <StyledContainer>
        <RepurchaseHeader.Title>{title}</RepurchaseHeader.Title>
      </StyledContainer>
      {children}
    </>
  )
}

export function LastOrderNonExistent(props: RepurchaseEmptyStateProps) {
  const { title } = props

  return (
    <RepurchaseEmptyStateLayout title={title}>
      <EmptyState
        {...commonProps}
        image={images.ILLUSTRATION_EMPTY_CART}
        title="Aún no has hecho tu primera compra"
        subtitle="Los productos de tu último pedido aparecerán aquí"
      />
    </RepurchaseEmptyStateLayout>
  )
}

export function LastOrderNotAvailableItems(props: RepurchaseEmptyStateProps) {
  const { title } = props

  return (
    <RepurchaseEmptyStateLayout title={title}>
      <EmptyState
        {...commonProps}
        image={images.ILLUSTRATION_FILE_ALERT}
        title="Productos no disponibles"
        subtitle="Puede que los productos de tu último pedido hayan sido despublicados o eliminados de la tienda"
      />
    </RepurchaseEmptyStateLayout>
  )
}

export function MostPurchasedNonExistent(props: RepurchaseEmptyStateProps) {
  const { title } = props

  return (
    <RepurchaseEmptyStateLayout title={title}>
      <EmptyState
        {...commonProps}
        image={images.ILLUSTRATION_EMPTY_CART}
        title="Aún no has hecho tu primera compra"
        subtitle="Los productos que más compres aparecerán aquí"
      />
    </RepurchaseEmptyStateLayout>
  )
}

export function MostPurchasedNotAvailableItems(props: RepurchaseEmptyStateProps) {
  const { title } = props

  return (
    <RepurchaseEmptyStateLayout title={title}>
      <EmptyState
        {...commonProps}
        image={images.ILLUSTRATION_FILE_ALERT}
        title="Productos no disponibles"
        subtitle="Puede que los productos que más has comprado hayan sido despublicados o eliminados de la tienda"
      />
    </RepurchaseEmptyStateLayout>
  )
}
