import * as React from 'react'

import { Dialog, DialogProps, Message } from '~/components'
import Button from '~/truck/button'
import Checkbox from '~/truck/checkbox'
import theme from '~/truck/theme'
import { storage } from '~/utils'
import { images } from '~/utils/constants'

export interface RepurchaseDialogProps extends DialogProps {
  onAccept: () => void
}

export const REPURCHASE_DIALOG_CHECKBOX_ID = 'repurchase-dialog-checkbox'

export function RepurchaseDialog(props: RepurchaseDialogProps) {
  const { onAccept, ...rest } = props

  const [isChecked, setIsChecked] = React.useState<boolean>(false)

  return (
    <Dialog
      {...rest}
      header={<Dialog.Header closable />}
      content={
        <Dialog.Content>
          <Message
            title="Tu lista de productos en el carrito"
            content="Los productos que agregues y se encuentren en tu carrito serán remplazados con los nuevos valores."
            imageSrc={images.ILLUSTRATION_SHOPPING_CART}
          />
          <Checkbox
            id={REPURCHASE_DIALOG_CHECKBOX_ID}
            style={{ marginTop: theme.spacing.relaxed }}
            checked={isChecked}
            onChange={e => setIsChecked(e.target.checked)}
          >
            No mostrar de nuevo
          </Checkbox>
        </Dialog.Content>
      }
      accept={
        <Button
          color="secondary"
          onClick={() => {
            storage.set(REPURCHASE_DIALOG_CHECKBOX_ID, isChecked)

            onAccept()
          }}
        >
          Agregar al carrito
        </Button>
      }
      cancel={
        <Button.Ghost color="grayDark" onClick={rest.onClose}>
          Cancelar
        </Button.Ghost>
      }
    />
  )
}
