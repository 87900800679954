import * as React from 'react'

import { useModalManager } from '~/hooks'
import { storage } from '~/utils'

import { REPURCHASE_DIALOG_CHECKBOX_ID, RepurchaseDialog } from '../dialog'
import type { FormValues, OnSubmitFn } from '../types'

export function useRepurchaseOnSubmit(onSubmit: OnSubmitFn) {
  const modalManager = useModalManager()

  const onSubmitRef = React.useRef<OnSubmitFn>()

  function openConfirmDialog() {
    modalManager.openModal(RepurchaseDialog, {
      onClose: () => {
        onSubmitRef.current = openConfirmDialog

        modalManager.closeModal()
      },
      onAccept: () => {
        onSubmitRef.current = onSubmit

        document
          .getElementById('repurchase-form')
          .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))

        modalManager.closeModal()
      },
    })
  }

  React.useEffect(() => {
    const cannotShowConfirmDialog = storage.get(REPURCHASE_DIALOG_CHECKBOX_ID)

    if (cannotShowConfirmDialog) {
      onSubmitRef.current = onSubmit
    } else {
      onSubmitRef.current = openConfirmDialog
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (formValues: FormValues) => onSubmitRef.current(formValues)
}
