import * as React from 'react'
import { Form as FinalForm } from 'react-final-form'
import { useMutation, useQuery, UseQueryResult } from 'react-query'
import debug from 'debug'
import { find, isEmpty, isNil, map } from 'lodash'
import { useFlag } from 'toggled'

import { analytics } from '~/analytics'
import { Loader } from '~/components'
import type { HTTPError } from '~/errors'
import { useVendor } from '~/hooks'
import type { SuperfetchOptions } from '~/lib/superfetch'
import { mixpanel } from '~/metrics'
import notifier from '~/truck/notifier'
import type { ICatalogQueryData } from '~/types/catalog'
import type { IMostPurchasedProductsData, IUser } from '~/types/users'
import { forceReloadTo } from '~/utils'
import { analyticsItemListContexts, defaultFinalFormDecorators, events, flags } from '~/utils/constants'

import { RepurchaseButton } from '../button'
import { StyledContainer, StyledForm } from '../elements'
import { MostPurchasedNonExistent, MostPurchasedNotAvailableItems } from '../empty-states'
import { RepurchaseHeader } from '../header'
import { RepurchaseLineItem } from '../line-item'
import type { FormValues } from '../types'
import { useRepurchaseOnSubmit } from '../use-on-submit'

import { checkNotAvailableItems, getCartTotal, getPayload, onValidate } from './utils'

const logger = debug('sellers:repurchase:contents:most-purchased')

export interface MostPurchasedContentProps {
  user: IUser
}

export function getCatalogParams(orderQuery: UseQueryResult<IMostPurchasedProductsData>) {
  if (!orderQuery.isSuccess) {
    return null
  }

  const skus = map(orderQuery.data?.data, 'sku')

  if (isEmpty(skus)) {
    return null
  }

  return {
    page: 1,
    sku: skus,
    limit: 10,
  }
}

export function getSortedPairs(
  catalogQuery: UseQueryResult<ICatalogQueryData>,
  mostPurchasedQuery: UseQueryResult<IMostPurchasedProductsData>,
) {
  if (!catalogQuery.isSuccess || !mostPurchasedQuery.isSuccess) {
    return null
  }

  return mostPurchasedQuery.data.data
    .map(purchasedItem => {
      const product = find(catalogQuery.data.data, { sku: purchasedItem.sku })

      if (!product) {
        return null
      }

      return { purchasedItem, product }
    })
    .filter(Boolean)
}

function getInitialValues(
  catalogQuery: UseQueryResult<ICatalogQueryData>,
  mostPurchasedQuery: UseQueryResult<IMostPurchasedProductsData>,
) {
  if (!catalogQuery.isSuccess || !mostPurchasedQuery.isSuccess) {
    return null
  }

  const sortedPairs = getSortedPairs(catalogQuery, mostPurchasedQuery)

  const lineItems = sortedPairs.map(sortedPair => {
    const { product, purchasedItem } = sortedPair

    return {
      sku: product.sku,
      price: product.price,
      stock: product.stock,
      disabled:
        Number(product.stock) === 0 ||
        (!isNil(product.minimumSalesQuantity) && Number(product.stock) < product.minimumSalesQuantity),
      purchasedTimes: purchasedItem.times,
      tierPricing: product.tierPricing,
    }
  })

  const sortedCatalogItems = map(sortedPairs, 'product')

  return {
    sortedCatalogItems,
    formValues: {
      lineItems,
    },
  }
}

export function MostPurchasedContent(props: MostPurchasedContentProps) {
  const { user } = props

  const vendor = useVendor()

  const canHandleStock = useFlag(flags.HANDLE_STOCK)

  const mostPurchasedQuery = useQuery<IMostPurchasedProductsData, HTTPError>('/account/purchased-products')

  const catalogParams = getCatalogParams(mostPurchasedQuery)

  const catalogQuery = useQuery<ICatalogQueryData, HTTPError>(['/catalog/products', catalogParams], {
    enabled: !!catalogParams,
  })

  const { mutateAsync } = useMutation<unknown, HTTPError, SuperfetchOptions>({})

  const initials = React.useMemo(() => {
    return getInitialValues(catalogQuery, mostPurchasedQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogQuery.dataUpdatedAt, mostPurchasedQuery.dataUpdatedAt])

  const [eventHasTriggered, setEventHasTriggered] = React.useState(false)

  React.useEffect(() => {
    const triggerViewItemListEvent = () => {
      const analyticsItems = map(initials.sortedCatalogItems, item => ({
        ...item,
        category: item.categories[0],
      }))

      analytics.viewItemList(analyticsItems, analyticsItemListContexts.MOST_PURCHASED_PRODUCTS)

      setEventHasTriggered(true)
    }

    if (!initials || eventHasTriggered) {
      return
    }

    triggerViewItemListEvent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initials])

  async function onSubmit(formValues: FormValues) {
    const payload = getPayload(formValues, user)

    try {
      await mixpanel.asyncTrack(events.PRODUCTS_ADDED_TO_CART_FROM_MOST_PURCHASED_PRODUCTS, {
        userId: user.originalId,
        vendorSlug: vendor.slug,
        vendorId: vendor.originalId,
      })
    } catch (error) {
      logger('Mixpanel tracking failed', error)
    }

    try {
      await mutateAsync({
        endpoint: '/account/cart',
        data: payload,
        method: 'PUT',
      })

      notifier.success('Productos agregados al carrito.')

      forceReloadTo('/carrito')
    } catch (error) {
      notifier.error('Hubo un error inesperado.')
    }
  }

  const decoratedOnSubmit = useRepurchaseOnSubmit(onSubmit)

  if (mostPurchasedQuery.isLoading || catalogQuery.isLoading) {
    return <Loader />
  }

  const commonEmptyStateProps = {
    title: 'Productos más comprados',
  }

  if (isEmpty(mostPurchasedQuery.data?.data)) {
    return <MostPurchasedNonExistent {...commonEmptyStateProps} />
  }

  if (checkNotAvailableItems(catalogQuery.data)) {
    return <MostPurchasedNotAvailableItems {...commonEmptyStateProps} />
  }

  return (
    <>
      <RepurchaseHeader title={commonEmptyStateProps.title} subtitle="Revisa los productos que más has comprado." />
      <FinalForm<FormValues>
        initialValues={initials.formValues}
        onSubmit={decoratedOnSubmit}
        validate={formValues => onValidate(formValues, { canHandleStock })}
        decorators={defaultFinalFormDecorators}
      >
        {formProps => {
          return (
            <StyledForm onSubmit={formProps.handleSubmit}>
              <StyledContainer>
                {map(initials.sortedCatalogItems, (catalogProduct, index) => {
                  const { purchasedTimes } = formProps.values.lineItems[index]

                  return (
                    <RepurchaseLineItem
                      key={catalogProduct.id}
                      fieldName={`lineItems[${index}].quantity`}
                      catalogProduct={catalogProduct}
                      purchasedTimes={purchasedTimes}
                    />
                  )
                })}
              </StyledContainer>
              <RepurchaseButton loading={formProps.submitting} cartTotal={getCartTotal(formProps.values)} />
            </StyledForm>
          )
        }}
      </FinalForm>
    </>
  )
}
