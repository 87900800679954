import * as React from 'react'
import styled from 'styled-components'

import Label from '~/truck/label'

export interface RepurchaseHeaderProps {
  title: React.ReactNode
  subtitle: string
}

export interface RepurchaseHeaderTitle {
  children: RepurchaseHeaderProps['title']
}

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing.comfortable}px;

  > *:not(:last-child) {
    margin-bottom: ${props => props.theme.spacing.cozy}px;
  }
`

function RepurchaseHeaderTitle(props: RepurchaseHeaderTitle) {
  return <Label as="h1" $textStyle="h4Semibold" {...props} />
}

export function RepurchaseHeader(props: RepurchaseHeaderProps) {
  const { title, subtitle } = props

  return (
    <StyledContainer>
      {typeof title === 'string' ? <RepurchaseHeaderTitle>{title}</RepurchaseHeaderTitle> : title}
      <Label as="h2" $textStyle="h6Regular">
        {subtitle}
      </Label>
    </StyledContainer>
  )
}

RepurchaseHeader.Title = RepurchaseHeaderTitle
