import { compact, fromPairs, isEmpty, isNumber, keys, sum } from 'lodash'
import Validate from 'validate.js'

import { getPriceFromTier } from '~/components/product/shared'
import type { ICatalogQueryData } from '~/types/catalog'
import type { IUser } from '~/types/users'

import type { FormErrors, FormValues, ValidationFlags } from '../types'

export function getCartTotal(formValues: FormValues) {
  return sum(
    formValues.lineItems
      .filter(lineItem => !lineItem.disabled && isNumber(lineItem.quantity))
      .map(lineItem => getPriceFromTier(lineItem) * Number(lineItem.quantity)),
  )
}

export function checkNotAvailableItems(data?: ICatalogQueryData) {
  return isEmpty(data?.data)
}

export function onValidate(formValues: FormValues, flags: ValidationFlags) {
  const lineItems = formValues.lineItems.map(lineItem => {
    if (lineItem.disabled || !flags.canHandleStock) {
      return null
    }

    const quantity = Validate.single(lineItem.quantity, {
      numericality: {
        lessThanOrEqualTo: lineItem.stock,
        notLessThanOrEqualTo: `%{count} disponible${lineItem.stock > 1 ? 's' : ''}`,
      },
    })

    if (!quantity) {
      return null
    }

    return { quantity }
  })

  const errors: FormErrors = {
    lineItems,
  }

  if (isEmpty(compact(lineItems))) {
    delete errors.lineItems
  }

  return errors
}

export function getPayload(formValues: FormValues, user: IUser) {
  // prettier-ignore
  const cartItemsQuantitiesBySku = fromPairs(
    user.cart.lineItems
      .map(lineItem => [lineItem.product.sku, lineItem.quantity]),
  )

  // prettier-ignore
  const formItemsQuantitiesBySku = fromPairs(
    formValues.lineItems
      .filter(lineItem => !lineItem.disabled && lineItem.quantity > 0)
      .map(lineItem => [lineItem.sku, lineItem.quantity]),
  )

  const quantitiesBySku = {
    ...cartItemsQuantitiesBySku,
    ...formItemsQuantitiesBySku,
  }

  return keys(quantitiesBySku).map(key => ({
    sku: key,
    quantity: quantitiesBySku[key],
  }))
}
