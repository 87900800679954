import * as React from 'react'
import { useField } from 'react-final-form'
import { debounce, isNumber } from 'lodash'
import Link from 'next/link'
import styled from 'styled-components'
import { useFeature, useFlag } from 'toggled'

import { analytics } from '~/analytics'
import { Counter, Price } from '~/components'
import { getPriceFromTier, unitTooltipDuration } from '~/components/product/shared'
import { UnitTooltip } from '~/components/unit-tooltip'
import { useSalesQuantity } from '~/hooks'
import { getQuantityRange } from '~/hooks/use-counter'
import Image from '~/truck/image'
import theme from '~/truck/theme'
import type { ICatalogProduct } from '~/types/catalog'
import { validateIfCanJumpOnce } from '~/utils'
import { cloudinary } from '~/utils'
import { analyticsItemListContexts, flags, images, transformations } from '~/utils/constants'
import { stack } from '~/variables'

export interface RepurchaseLineItemProps {
  catalogProduct: ICatalogProduct
  fieldName: string
  purchasedTimes?: number
}

export interface RepurchaseLineItemImageProps {
  src: string
  height?: number
  width?: number
  isOutOfStock?: boolean
}

interface RepurchaseLineItemCounterProps {
  fieldName: string
  isOutOfStock?: boolean
  counterMaxValue?: number
  counterMinValue?: number
  counterMaxMessage?: string
  catalogProduct: ICatalogProduct
}

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const CounterContainer = styled.div`
  align-items: center;
  bottom: ${props => props.theme.spacing.cozy * 3.25}px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: ${props => props.theme.spacing.cozy}px;
  z-index: 1;

  > span {
    margin-top: ${props => props.theme.spacing.compact}px;
  }
`

const ImageContainer = styled.div`
  align-items: center;
  align-self: center;
  border: 1px solid ${props => props.theme.colors.gray};
  border-radius: ${props => props.theme.spacing.cozy / 2}px;
  display: flex;
  overflow: hidden;
  padding: ${props => props.theme.spacing.cozy}px;
  position: relative;
`

const RepurchaseImageContainer = styled.div`
  align-items: center;
  display: flex;
`

const InlineContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  padding: ${props => props.theme.spacing.comfortable}px;
  position: relative;

  > *:not(:last-child) {
    margin-right: ${props => props.theme.spacing.comfortable}px;
  }

  > ${InfoContainer} {
    flex: 1;
  }
`

const StyledOverlay = styled.div`
  align-items: center;
  background: ${props => props.theme.colors.white70};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

const StyledCounter = styled(Counter)`
  align-self: flex-end;
  justify-content: flex-end;
`

const StyledCounterError = styled(Counter.Error)`
  position: absolute;
  top: 100%;
`

const selectItemUrlMap = {
  'mas-comprados': analyticsItemListContexts.MOST_PURCHASED_PRODUCTS,
  'ultimo-pedido': analyticsItemListContexts.LAST_ORDER,
}

function getPurchasedTimesText(purchasedTimes: number) {
  return purchasedTimes ? `En ${purchasedTimes} pedido${purchasedTimes > 1 ? 's' : ''}` : null
}

export function RepurchaseLineItemImage(props: RepurchaseLineItemImageProps) {
  const { isOutOfStock, src, ...rest } = props

  return (
    <ImageContainer>
      {isOutOfStock && (
        <StyledOverlay>
          <p className="text-center text-xs font-semibold">
            Fuera de <br /> stock
          </p>
        </StyledOverlay>
      )}
      <Image
        height={theme.spacing.cozy * 9}
        width={theme.spacing.cozy * 9}
        src={cloudinary.applyTransformations(src ?? images.PRODUCT_PLACEHOLDER, transformations.TINY_PRODUCT)}
        {...rest}
      />
    </ImageContainer>
  )
}

function RepurchaseLineItemCounter(props: RepurchaseLineItemCounterProps) {
  const { isOutOfStock, fieldName, counterMaxValue, counterMinValue, counterMaxMessage, catalogProduct } = props

  const currentLineItemField = useField(fieldName)

  const hasUnits = useFlag(flags.UNITS)

  const [counterState, setCounterState] = React.useState({
    isOpen: false,
    errorMessage: null,
  })

  const onCounterOpen = () => {
    setCounterState(prevState => ({ ...prevState, isOpen: true }))
  }

  const onCounterClose = () => {
    setCounterState(prevState => ({ ...prevState, isOpen: false }))
  }

  const onCounterError = (error: string) => {
    setCounterState(prevState => ({ ...prevState, errorMessage: error }))
  }

  if (isOutOfStock) {
    return null
  }

  const counterCommonProps = {
    maxMessage: counterMaxMessage,
    maxValue: counterMaxValue,
    minValue: counterMinValue,
    jumpFactor: catalogProduct.jumpFactor,
    allowZeroToEliminate: true,
    value: isNumber(currentLineItemField.input.value) ? currentLineItemField.input.value : 0,
    onError: onCounterError,
    onOpen: onCounterOpen,
    onClose: onCounterClose,
    onChange: debounce(value => {
      currentLineItemField.input.onChange(value)
    }, 200),
  }

  return (
    <>
      {counterState.isOpen && <StyledOverlay />}
      <CounterContainer>
        {!hasUnits ? (
          <StyledCounter {...counterCommonProps} />
        ) : (
          <UnitTooltip
            visible={counterState.isOpen && currentLineItemField.input.value > 0}
            duration={unitTooltipDuration}
            salesUnitFactor={catalogProduct.salesUnitFactor}
            inventoryUnitCode={catalogProduct.inventoryUnit?.code}
            quantity={currentLineItemField.input.value}
            zIndex={stack.mainHeader - 1}
          >
            <StyledCounter {...counterCommonProps} />
          </UnitTooltip>
        )}
        {counterState.errorMessage && counterState.isOpen && <StyledCounterError message={counterState.errorMessage} />}
      </CounterContainer>
    </>
  )
}

export function RepurchaseLineItem(props: RepurchaseLineItemProps) {
  const { catalogProduct, purchasedTimes, fieldName } = props

  const productUrl = `/p/${catalogProduct.slug}`

  const { handleSalesQuantity } = useSalesQuantity()

  const showSkuLabelFeature = useFeature(flags.SHOW_SKU_LABEL)

  const purchasedTimesText = getPurchasedTimesText(purchasedTimes)

  const { counterMaxValue, counterMinValue, stock, counterMaxMessage } = handleSalesQuantity({
    maximumSalesQuantity: catalogProduct.maximumSalesQuantity,
    minimumSalesQuantity: catalogProduct.minimumSalesQuantity,
    stock: catalogProduct.stock,
  })

  const canJumpAtLeastOnce = validateIfCanJumpOnce(catalogProduct.jumpFactor, counterMinValue, counterMaxValue)

  const { maxValue } = getQuantityRange({
    minValue: counterMinValue,
    maxValue: counterMaxValue,
    jumpFactor: catalogProduct.jumpFactor,
    allowZeroToEliminate: true,
  })

  const realStock = Number(stock ?? catalogProduct.stock)

  const stockToDisplay = counterMaxValue ? maxValue : realStock

  const isOutOfStock = realStock <= 0 || !canJumpAtLeastOnce

  const lineItemQuantity = useField(fieldName).input.value

  const price = getPriceFromTier({ ...catalogProduct, quantity: lineItemQuantity })

  const triggerAnalyticsSelectItemEvent = () => {
    const currentPath = window.location.pathname.split('/')[2]

    const currentContext = selectItemUrlMap[currentPath]

    const analyticsProduct: ICatalogProduct = {
      ...catalogProduct,
      pricing: { price },
      category: catalogProduct.categories[0],
    }

    analytics.selectItem(analyticsProduct, currentContext)
  }

  const showProductStock = useFeature(flags.SHOW_PRODUCT_STOCK)

  const handleStock = useFeature(flags.HANDLE_STOCK)

  const shouldShowStock = showProductStock && handleStock && realStock > 0 && canJumpAtLeastOnce

  return (
    <InlineContainer>
      <RepurchaseImageContainer>
        <Link href={productUrl}>
          <a onClick={triggerAnalyticsSelectItemEvent}>
            <RepurchaseLineItemImage isOutOfStock={isOutOfStock} src={catalogProduct.photo} />
          </a>
        </Link>
      </RepurchaseImageContainer>
      <div className="flex flex-col justify-center gap-1">
        <Link passHref href={productUrl}>
          <a className="truncate" onClick={triggerAnalyticsSelectItemEvent}>
            {catalogProduct.name}
          </a>
        </Link>
        {catalogProduct.presentation && (
          <p className="truncate text-xs text-secondary">{catalogProduct.presentation}</p>
        )}
        {catalogProduct.attributesSummary && (
          <p className="truncate text-xs text-tertiary">{catalogProduct.attributesSummary}</p>
        )}
        {showSkuLabelFeature && <p className="truncate text-xs text-tertiary">SKU: {catalogProduct.sku}</p>}
        {shouldShowStock && (
          <p className="text-xs text-green-600">
            <span className="text-xs font-semibold">{stockToDisplay > 9999 ? '+9999' : stockToDisplay}</span> en stock
          </p>
        )}
        <Price
          value={price}
          oldValue={catalogProduct.oldPrice}
          isTaxExempt={catalogProduct.isTaxExemptPrice}
          tierPricing={catalogProduct.tierPricing}
          allowTaxLabel
          allowDiscountLabel
        />
        {purchasedTimesText && <span className="text-xs text-secondary">{purchasedTimesText}</span>}
      </div>
      <RepurchaseLineItemCounter
        isOutOfStock={isOutOfStock}
        fieldName={fieldName}
        counterMaxValue={counterMaxValue}
        counterMinValue={counterMinValue}
        counterMaxMessage={counterMaxMessage}
        catalogProduct={catalogProduct}
      />
    </InlineContainer>
  )
}
